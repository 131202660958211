import icon01 from '../images/icon-01.png'
import icon02 from '../images/icon-02.png'
import icon03 from '../images/icon-03.png'
import icon04 from '../images/icon-04.png'
import icon05 from '../images/icon-05.png'
import icon06 from '../images/icon-06.png'
import right01 from '../images/right-01.png'

import banner01 from '@/assets/images/banner01.jpg'
import banner02 from '@/assets/images/banner02.jpg'
import banner03 from '@/assets/images/banner03.jpg'
import banner04 from '@/assets/images/banner04.jpg'
import banner05 from '@/assets/images/banner05.jpg'

import { v4 as uuid } from 'uuid'
export const bannerList: API.Home.Banner[] = [
  {
    url: banner01
  },
  {
    url: banner02
  },
  {
    url: banner03
  },
  {
    url: banner04
  },
  {
    url: banner05
  }
]

export const entranceList: API.Home.Entrance[] = [
  {
    title: '高企自评入口',
    icon: icon01,
    side: right01,
    type: 'link',
    link: 'https://zsfw.gjzwfw.gov.cn/qykj/shell_oc/enterprise/serve/policyTestDetail?pid=5fad07bd2f244010f3abdace'
  },
  {
    title: '高企立即申报',
    icon: icon02,
    side: right01,
    type: 'link',
    link: 'https://fuwu.most.gov.cn/html/zxbl/cxjg/?search=%E9%AB%98%E6%96%B0%E6%8A%80%E6%9C%AF%E4%BC%81%E4%B8%9A%E8%AE%A4%E5%AE%9A'
  },
  {
    title: '高企诉求反馈',
    icon: icon03,
    side: right01,
    type: 'link',
    link: 'https://qyfw.tfgyc.com/#/sqt'
  },
  {
    title: '政策清单',
    icon: icon04,
    side: right01,
    type: 'path',
    link: '/policylist'
  },
  {
    title: '高企申报认定问答',
    icon: icon05,
    side: right01,
    type: 'path',
    link: '/highDeclaration'
  },
  {
    title: '诉求问答清单',
    icon: icon06,
    side: right01,
    type: 'path',
    link: '/appealAnswer'
  }
]

export const policyTableList = () => {
  const list: API.PolicyList.PolicyListColumns[] = []
  for (let i = 0; i < 20; i++) {
    list.push({
      id: uuid(),
      supportedObjects: '数字经济',
      policyType: '载体支持',
      supportedCondition: '年销售达2000万元，利用市外资金购买办公载体',
      supportedType: '后补助',
      supportedStandard: '按照实陈购房款10%，分三年最高1000万元补助',
      policyDerivation: '《四川天府新区成都直管区加快数字经济高质》',
      typeOfPolicy: '普适政策',
      policyTimelinessStart: '2022年11月25日',
      policyTimelinessEnd: '2025年11月24日',
      policyAddress: '天府新区'
    })
  }
  return list
}

export const highDeclarationList: API.HighDeclaration.HighDeclarationList[] = [
  {
    id: uuid(),
    title: '天府新区高企线下服务窗口(高企认定纸质材料受理)',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '高新技术企业认定依据是什么?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '通过高新技术企业认定的有效期有多长，从什么时间开始算起?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '认定高新技术企业需符合什么条件?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '高企评分指标有哪些?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '企业申请高新技术企业认定，该如何选择技术领域?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '企业取得的发明专利在高企申报时可以使用几次?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '知识产权有多个权属人时，高企申报时需要提供哪些材料?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '职工总数的定义是什么?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '什么是科技人员?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '如何统计科技人员?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  }
]
export const appealAnswList: API.AppealAnswer.AppealAnswerList[] = [
  {
    id: uuid(),
    title:
      '如何申报四川省重大技术装备首台套、软件首版次、新材料新批次推广应用指导目录和产品认定?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title:
      '创新型中小企业、省级“专精特新”中小企业、国家级专精特新“小巨人”如何申报?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title:
      '企业名称已在四川政务服务网上通过了自主申报，为什么申请材料仍因名称不合规范被退回?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title:
      '线上怎么获取市场主体设立、变更、备案、注销等相关业务办事指南、电子版资料、参考模板?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '企业减少注册资本变更流程与所需资料是什么?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '企业申请高新技术企业认定，该如何选择技术领域?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '企业取得的发明专利在高企申报时可以使用几次?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '知识产权有多个权属人时，高企申报时需要提供哪些材料?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '职工总数的定义是什么?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '什么是科技人员?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  },
  {
    id: uuid(),
    title: '如何统计科技人员?',
    answerContent:
      '《高新技术企业认定管理办法》(国料发文[2016132号)《高新技术企业认定管理工作指引》(国科发文[20161195号)《中华人民共和国企业所得税法》《中华人民共和国企业所得税法实施条例》'
  }
]
