import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import store from './store'
import './assets/styles/index.scss'
import 'lib-flexible'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <HashRouter>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </HashRouter>
  </Provider>
)

reportWebVitals()
