import React, { memo } from 'react'
import type { FC, ReactNode } from 'react'
import { Image } from 'antd'
import './style.scss'
// import logo from '@/assets/images/logo.jpg'
import logo from '@/assets/images/title-logo.png'
import { NavLink, useLocation } from 'react-router-dom'
import { getNowDate } from '@/utils/format'
import { useNavigate } from 'react-router-dom'

interface IProps {
  children?: ReactNode
}
const HeaderTitle: API.Header.Menu[] = [
  {
    title: '首页',
    type: 'path',
    link: '/home'
  },
  {
    title: '政策清单',
    type: 'path',
    link: '/policylist'
  },
  {
    title: '高企申报认定回答',
    type: 'path',
    link: '/highDeclaration'
  },
  {
    title: '诉求回答',
    type: 'path',
    link: '/appealAnswer'
  }
]

const AppHeader: FC<IProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  /* 定义组件内部展示状态 */
  const showItem = (item: API.Header.Menu) => {
    if (item.type === 'path') {
      return (
        <NavLink
          to={item.link}
          className={({ isActive }) => {
            return isActive ? 'active' : undefined
          }}
        >
          {item.title}
          <i className="icon"></i>
        </NavLink>
      )
    } else {
      return (
        <a target="_blank" rel="noreferrer" href={item.link}>
          {item.title}
        </a>
      )
    }
  }

  const handelCilck = () => {
    if (location.pathname === '/home') return
    navigate('/home', { replace: true })
  }
  return (
    <div className="HeaderWrapper">
      <div className="content">
        <div className="HeaderLeft">
          <Image
            onClick={handelCilck}
            className="tilte-logo"
            src={logo}
            preview={false}
          />

          {/* <Image src={logo} preview={false} />

          <h3 className="logo">四川天府新区高新技术企业线上服务窗口</h3> */}
        </div>
        <div className="HeaderRight">
          <div className="title-list">
            {/* {HeaderTitle.map((item: API.Header.Menu) => {
              return (
                <div key={item.title} className="item">
                  {showItem(item)}
                </div>
              )
            })} */}
            <div className="now-date">今天是{getNowDate()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(AppHeader)
