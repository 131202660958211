import { BASE_URL, TIME_OUT } from './config'
import XPRequest from './request'

const xpRequest = new XPRequest({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : BASE_URL,
  // baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestSuccessFn: (config) => {
      return config
    }
  }
})

export default xpRequest
