import React, { memo } from 'react'
import type { FC, ReactNode } from 'react'
import './style.scss'

interface IProps {
  children?: ReactNode
}

const AppFooter: FC<IProps> = () => {
  return (
    <div className="AppFooter">
      Copyright©2024 四川天府新区高新技术企业线上服务 All Rights
      Reserved.蜀ICP备2020031688号-1
    </div>
  )
}
export default memo(AppFooter)
