import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { entranceList, bannerList } from '@/assets/data/header-title'
import { getAnswerList, getPolicyList } from '@/servers/modules/theme'
import { IRootState } from '..'

interface IThunkState {
  state: IRootState
}
interface IThemeState {
  bannerList: API.Home.Banner[]
  entranceList: API.Home.Entrance[]
  highDeclarationList: API.HighDeclaration.HighDeclarationList[]
  policyTableList: API.PolicyList.PolicyListColumns[]
  appealAnswList: API.AppealAnswer.AppealAnswerList[]
}

export const fetchThemeDataAction = createAsyncThunk<void, object, IThunkState>(
  'theme',
  (params: any, { dispatch }) => {
    getPolicyList(params).then((res) => {
      if (res.data) {
        dispatch(changePolicyList(res.data.rows))
      }
    })
  }
)
export const featchThemeListAction = createAsyncThunk<void, any, IThunkState>(
  'theme1',
  ({ type, keyWord }, { dispatch }) => {
    getAnswerList({ type, keyWord }).then((res) => {
      if (type === 1) {
        dispatch(changeHighDeclarationList(res.data.rows))
      } else {
        dispatch(changeAppealAnswList(res.data.rows))
      }
    })
  }
)

const initialState: IThemeState = {
  entranceList,
  policyTableList: [],
  highDeclarationList: [],
  appealAnswList: [],
  bannerList
}

const ThemeReducer = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changePolicyList(state, { payload }) {
      state.policyTableList = payload
    },
    changeHighDeclarationList(state, { payload }) {
      state.highDeclarationList = payload
    },
    changeAppealAnswList(state, { payload }) {
      state.appealAnswList = payload
    }
  }
})

export const {
  changePolicyList,
  changeHighDeclarationList,
  changeAppealAnswList
} = ThemeReducer.actions
export default ThemeReducer.reducer
