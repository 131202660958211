import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import AppHeader from './components/AppHeader'
import routes from './router'
import { hot } from 'react-hot-loader/root'
import AppFooter from './components/AppFooter'

function App() {
  return (
    <div className="App">
      <AppHeader />
      {/* 使用了懒加载必用Suspense,Suspense站位防止切换路由闪烁 */}
      <Suspense fallback="">
        <div className="main">
          {useRoutes(routes)}
          <AppFooter />
        </div>
      </Suspense>
    </div>
  )
}

export default hot(App)
