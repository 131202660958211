// 发送网路请求
import xpRequest from '..'

export const getPolicyList = (params: API.PolicyList.PolicyQuery) => {
  return xpRequest.get({
    url: '/-get-zcs.htm',
    params: params
  })
}
export const getAnswerList = (params: any) => {
  return xpRequest.get({
    url: '/-get-zc-answer.htm',
    params: params
  })
}
export const getDownList = (params: any) => {
  return xpRequest.get({
    url: '/-get-zc-down-list.htm',
    params: params
  })
}
