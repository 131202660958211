import { configureStore } from '@reduxjs/toolkit'
import {
  shallowEqual,
  useDispatch,
  useSelector,
  type TypedUseSelectorHook
} from 'react-redux'
import ThemeReducer from './modules/theme'

const store = configureStore({
  reducer: {
    theme: ThemeReducer
  }
})

// 声明hook的类型
type getStateType = typeof store.getState
export type IRootState = ReturnType<getStateType>
type DispatchType = typeof store.dispatch

// 封装APP的hook
export const useAPPSelector: TypedUseSelectorHook<IRootState> = useSelector
export const useAppDispatch: () => DispatchType = useDispatch
export const shallowEqualAPP = shallowEqual
export default store
