import { Navigate, RouteObject } from 'react-router-dom'
import React, { lazy } from 'react'
// 引入路由组件
const Home = lazy(() => import('@/views/Home'))
const PolicyList = lazy(() => import('@/views/PolicyList'))
const HighDeclaration = lazy(() => import('@/views/HighDeclaration'))
const AppealAnswer = lazy(() => import('@/views/AppealAnswer'))

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/home" />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/policylist',
    element: <PolicyList />
  },
  {
    path: '/highDeclaration',
    element: <HighDeclaration />
  },
  {
    path: '/appealAnswer',
    element: <AppealAnswer />
  }
]
export default routes
